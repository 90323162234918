var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "7"
    }
  }, [_c('form-tanggal', {
    on: {
      "submit": _vm.submit
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "5"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Akumulasi"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('ul', {
    staticClass: "list-unstyled w-100"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Total Nilai Aset (Rp) ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.getakumulasinilaiasset(_vm.assets))))])])]), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Total Nilai Buku (Rp) ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.getakumulasinilaibuku(_vm.assets))))])])]), _c('li', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Total Penyusutan (Rp) ")]), _c('div', {
    staticClass: "detail-amt text-info"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.getakumulasipenyusutan(_vm.assets))))])])])])])])], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_vm.assets ? _c('b-card', [_c('div', {
    staticClass: "d-flex justify-content-start mb-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.print($event);
      }
    }
  }, [_vm._v(" Download ")])], 1), _c('b-table', {
    attrs: {
      "small": "",
      "fields": _vm.fields,
      "items": _vm.assets,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(order)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(show_detail)",
      fn: function (row) {
        return [row.item.items && row.item.items.length > 0 ? _c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function ($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        })]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show Item"))])]) : _c('span', {
          staticClass: "text-danger"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SlashIcon"
          }
        })], 1)];
      }
    }, {
      key: "row-details",
      fn: function (_ref2) {
        var item = _ref2.item,
          toggleDetails = _ref2.toggleDetails;
        return [_c('b-card', [_c('b-row', {
          staticClass: "mb-2"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Nama Asset")]), _c('th', [_vm._v("Keterangan")]), _c('th', [_vm._v("Lokasi")]), _c('th', [_vm._v("Tanggal")]), _c('th', [_vm._v("Qty")]), _c('th', [_vm._v("Harga Akusisi (Rp)")]), _c('th', [_vm._v("Nilai Asset (Rp)")]), _c('th', [_vm._v("Tahun")]), _c('th', [_vm._v("Bulan")]), _c('th', [_vm._v("Depresi Bulan (Rp)")]), _c('th', [_vm._v("Nilai Buku (Rp)")]), _c('th', [_vm._v("Total Akumulasi (Rp)")])]), _vm._l(item.items, function (br, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(br.namaAsset ? ("" + (br.namaAsset)) : "-"))]), _c('td', [_vm._v(_vm._s(br.keterangan ? ("" + (br.keterangan)) : "-"))]), _c('td', [_vm._v(_vm._s(br.lokasi ? ("" + (br.lokasi)) : "-"))]), _c('td', [_vm._v(_vm._s(br.tanggal ? ("" + (_vm.humanDate(br.tanggal))) : "-"))]), _c('td', [_vm._v(_vm._s(br.qty ? _vm.formatRupiah(("" + (br.qty))) : "-"))]), _c('td', [_vm._v(_vm._s(br.hargaAkusisi ? _vm.formatRupiah(("" + (br.hargaAkusisi))) : "-"))]), _c('td', [_vm._v(_vm._s(br.nilaiAsset ? _vm.formatRupiah(("" + (br.nilaiAsset))) : "-"))]), _c('td', [_vm._v(_vm._s(br.tahun ? _vm.formatRupiah(("" + (br.tahun))) : "-"))]), _c('td', [_vm._v(_vm._s(br.bulan ? _vm.formatRupiah(("" + (br.bulan))) : "-"))]), _c('td', [_vm._v(_vm._s(br.depresiasiBulan ? _vm.formatRupiah(("" + (br.depresiasiBulan))) : "-"))]), _c('td', [_vm._v(_vm._s(br.nilaiBuku ? _vm.formatRupiah(("" + (br.nilaiBuku))) : "-"))]), _c('td', [_vm._v(_vm._s(br.totalAkumulasi ? _vm.formatRupiah(("" + (br.totalAkumulasi))) : "-"))])]);
        })], 2)])], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])], 1)];
      }
    }, {
      key: "cell(totalNilaiAsset)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.totalNilaiAsset)))])];
      }
    }, {
      key: "cell(totalNilaiBuku)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.totalNilaiBuku)))])];
      }
    }, {
      key: "cell(totalAkumulasiAll)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.totalAkumulasiAll)))])];
      }
    }], null, false, 963008467)
  })], 1) : _c('section', {
    staticClass: "alert alert-danger p-1"
  }, [_vm._v(" Harap pilih tanggal awal dan tanggal akhir untuk melihat hasil ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }