<template>
  <b-overlay :show="loading">

    <b-row class="justify-content-center">
      <b-col sm="12" md="7">
        <form-tanggal @submit="submit"></form-tanggal>
      </b-col>
      <b-col sm="12" md="5">
        <b-card title="Akumulasi">
          <div class="d-flex justify-content-between align-items-center">
            <ul class="list-unstyled w-100">
              <li class="d-flex justify-content-between mb-1">
                <div class="details-title">
                  Total Nilai Aset (Rp)
                </div>
                <div class="detail-amt">
                  <strong>{{ formatRupiah(getakumulasinilaiasset(assets)) }}</strong>
                </div>
              </li>
              <li class="d-flex justify-content-between mb-1">
                <div class="details-title">
                  Total Nilai Buku (Rp)
                </div>
                <div class="detail-amt">
                  <strong>{{ formatRupiah(getakumulasinilaibuku(assets)) }}</strong>
                </div>
              </li>
              <li class="d-flex justify-content-between">
                <div class="details-title">
                  Total Penyusutan (Rp)
                </div>
                <div class="detail-amt text-info">
                  <strong>{{ formatRupiah(getakumulasipenyusutan(assets)) }}</strong>
                </div>
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
      <b-col sm="12">
        <b-card v-if="assets">
          <div class="d-flex justify-content-start mb-2">
          <b-button @click.prevent="print" variant="outline-primary">
            Download
          </b-button>
          <!-- <h5 align="right" class="text-info d-flex justify-content-end">Total Akumulasi Nilai Aset : Rp {{ formatRupiah(getakumulasinilaiasset(assets)) }}</h5>
                        <h5 align="right" class="text-info d-flex justify-content-end">Total Akumulasi Nilai Buku : Rp {{ formatRupiah(getakumulasinilaibuku(assets)) }}</h5>
                        <h5 align="right" class="text-info d-flex justify-content-end">Total Akumulasi Penyusutan : Rp {{ formatRupiah(getakumulasipenyusutan(assets)) }}</h5> -->
          </div>
          <b-table small :fields="fields" :items="assets" responsive>
            <template #cell(order)="{ index }">
              {{ ++index }}
            </template>
            <template #cell(show_detail)="row">
              <b-form-checkbox v-if="row.item.items && row.item.items.length > 0" v-model="row.detailsShowing" plain
                class="vs-checkbox-con" @change="row.toggleDetails">
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                  </span>
                </span>
                <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show Item" }}</span>
              </b-form-checkbox>
              <span v-else class="text-danger">
                <feather-icon icon="SlashIcon" />
              </span>
            </template>
            <!-- / -->
            <!-- Detail -->
            <template #row-details="{ item, toggleDetails }">
              <b-card>
                <b-row class="mb-2">
                  <b-col cols="12">
                    <table>
                      <tr>
                        <th>No</th>
                        <th>Nama Asset</th>
                        <th>Keterangan</th>
                        <th>Lokasi</th>
                        <th>Tanggal</th>
                        <th>Qty</th>
                        <th>Harga Akusisi (Rp)</th>
                        <th>Nilai Asset (Rp)</th>
                        <th>Tahun</th>
                        <th>Bulan</th>
                        <th>Depresi Bulan (Rp)</th>
                        <th>Nilai Buku (Rp)</th>
                        <th>Total Akumulasi (Rp)</th>
                      </tr>
                      <tr :key="i" v-for="(br, i) in item.items">
                        <td>{{ i + 1 }}</td>
                        <td>{{ br.namaAsset ? `${br.namaAsset}` : "-" }}</td>
                        <td>{{ br.keterangan ? `${br.keterangan}` : "-" }}</td>
                        <td>{{ br.lokasi ? `${br.lokasi}` : "-" }}</td>
                        <td>{{ br.tanggal ? `${humanDate(br.tanggal)}` : "-" }}</td>
                        <td>{{ br.qty ? formatRupiah(`${br.qty}`) : "-" }}</td>
                        <td>{{ br.hargaAkusisi ? formatRupiah(`${br.hargaAkusisi}`) : "-" }}</td>
                        <td>{{ br.nilaiAsset ? formatRupiah(`${br.nilaiAsset}`) : "-" }}</td>
                        <td>{{ br.tahun ? formatRupiah(`${br.tahun}`) : "-" }}</td>
                        <td>{{ br.bulan ? formatRupiah(`${br.bulan}`) : "-" }}</td>
                        <td>{{ br.depresiasiBulan ? formatRupiah(`${br.depresiasiBulan}`) : "-" }}</td>
                        <td>{{ br.nilaiBuku ? formatRupiah(`${br.nilaiBuku}`) : "-" }}</td>
                        <td>{{ br.totalAkumulasi ? formatRupiah(`${br.totalAkumulasi}`) : "-" }}</td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>

                <b-button size="sm" variant="outline-secondary" @click="toggleDetails">
                  Hide
                </b-button>
              </b-card>
            </template>

            <template #cell(totalNilaiAsset)="{ item }">
              <strong>Rp {{ formatRupiah(item.totalNilaiAsset) }}</strong>
            </template>
            <template #cell(totalNilaiBuku)="{ item }">
              <strong>Rp {{ formatRupiah(item.totalNilaiBuku) }}</strong>
            </template>
            <template #cell(totalAkumulasiAll)="{ item }">
              <strong>Rp {{ formatRupiah(item.totalAkumulasiAll) }}</strong>
            </template>
          </b-table>
        </b-card>
        <section v-else class="alert alert-danger p-1">
          Harap pilih tanggal awal dan tanggal akhir untuk melihat hasil
        </section>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput, BFormCheckbox } from 'bootstrap-vue'
import FormTanggal from './components/FormTanggal'
export default {
  components: {
    FormTanggal,
    BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput, BFormCheckbox
  },
  computed: {
    assets() {
      return this.$store.state.laporanasset.assets
    }
  },
  data: () => ({
    loading: false,
    assets: [],
    form: {
      tgl_awal: null,
      tgl_akhir: null
    },
    fields: [
      { key: 'show_detail', label: '#' },
      { key: 'order', label: 'No' },
      { key: 'kategori', label: 'Kategori' },
      { key: 'totalNilaiAsset', label: 'total Nilai Asset' },
      { key: 'totalNilaiBuku', label: 'total Nilai Buku' },
      { key: 'totalAkumulasiAll', label: 'Total Akumulasi Penyusutan (All)' },
    ]
  }),
  methods: {

    getakumulasipenyusutan(assets) {
      return assets.reduce((total, assets) => total += assets.totalAkumulasiAll, 0)
    },
    getakumulasinilaibuku(assets) {
      return assets.reduce((total, assets) => total += assets.totalNilaiBuku, 0)
    },
    getakumulasinilaiasset(assets) {
      return assets.reduce((total, assets) => total += assets.totalNilaiAsset, 0)
    },
    async print() {
      if (!this.form.tgl_awal || !this.form.tgl_akhir) {
        this.displayError({
          message: 'Harap isi tanggal awal dan tanggal akhir!'
        })
        return false
      }

      const params = Object.assign({}, this.form)
      params.url = this.$route.meta.api

      this.loading = true
      const response = await this.$store.dispatch('laporanasset/getData', params)
      window.open(URL.createObjectURL(response))
      this.loading = false
    },
    async submit({ tgl_awal, tgl_akhir }) {

      this.loading = true
      this.form.tgl_awal = tgl_awal
      this.form.tgl_akhir = tgl_akhir
      this.assets = await this.$store.dispatch('laporanasset/getJournal', this.form)

      this.loading = false
      this.getakumulasipenyusutan(assets)
      this.getakumulasinilaibuku(assets)
      this.getakumulasinilaiasset(assets)
    }
  }
}
</script>