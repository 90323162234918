<template>
    <b-card class="mb-1" title="Pilih Tanggal Laporan">
        <b-row class="justify-content-between">
            <b-col sm="12" md="6" lg="4">
                <b-form-group label="Tanggal Awal">
                    <b-form-input type="date" v-model="form.tgl_awal"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="4">
                <b-form-group label="Tanggal Akhir">
                    <b-form-input type="date" v-model="form.tgl_akhir"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="4">
                <b-form-group label="--">
                    <b-button :disabled="!isValidForm" variant="primary" @click.prevent="submit">Lihat Laporan</b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import {BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput} from 'bootstrap-vue'
export default {
    components: {
        BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput
    },
    data: () => ({
        form: {
            tgl_awal: null,
            tgl_akhir: null
        }
    }),
    computed: {
        isValidForm() {
            if(!this.form.tgl_awal || !this.form.tgl_akhir) {
                return false
            }

            return true
        }
    },
    methods :{
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap pilih tanggal awal dan tanggal akhir!'
                })
                return false
            }

            this.$emit('submit', this.form)
        }
    }
}
</script>